<template>
  <div v-if="loaded" class="league-page">
    <div class="league">
      <div class="league-header">
        <div class="league-logo-champ"></div>
        <div class="league-title">CHAMPIONS LEAGUE</div>
        <div class="league-header-right">
          <div class="league-invoiced-txt">gefactureerde omzet</div>
          <div class="league-dates">{{formatDate(get_league.start_date)}} T/M {{formatDate(get_league.end_date)}}</div>
        </div>
      </div>
      <div class="league-content">
        <div class="league-partners">
          <template v-for="(item, index)  in get_league_data.slice(0,20)" :key="item.user_id">
          <div class="league-partner green-line" :data-partner-id="item.user_id">
            <div class="league-inner"><div class="league-rank">{{index + 1}}</div><div league-name="league-name">{{ item.company_name }}</div><div class="league-amount">&euro; {{ formatFee(item.amount) }}</div></div>
          </div>
          </template>
        </div>
        <div class="league-partners">
          <template v-for="(item, index)  in get_league_data.slice(20,40)" :key="item.user_id">
          <div class="league-partner" :data-partner-id="item.user_id" :class="{'green-line': index <= 4, 'orange-line': index > 4}">
            <div class="league-inner"><div class="league-rank">{{index + 21}}</div><div league-name="league-name">{{ item.company_name }}</div><div class="league-amount">&euro; {{ formatFee(item.amount) }}</div></div>
          </div>
          </template>
        </div>
        <div class="league-partners">
          <template v-for="(item, index)  in get_league_data.slice(40,60)" :key="item.user_id">
            <div class="league-partner orange-line" :data-partner-id="item.user_id">
              <div class="league-inner"><div class="league-rank">{{index + 41}}</div><div league-name="league-name">{{ item.company_name }}</div><div class="league-amount">&euro; {{ formatFee(item.amount) }}</div></div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="extra"></div>
  </div>
  <Footer />
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@700&display=swap');

html, body {
  height: 100%;
}
#app {
  height: 100%;
}
#wrapper {
  height: 100%;
}

.league-header {
  padding: 0px 16px;

  height: 84px;
}
.league-invoiced-txt{
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: right;
}
.league-dates{
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: right;
}
.green-line {
  border-color: #45e4bf;
  border-left-width: 6px;
  border-left-style: solid;
}

.orange-line {
  border-color: #fa9a00;
  border-left-width: 6px;
  border-left-style: solid;
}

.league-amount {
  margin-left: auto;
}

.league-rank {
  margin-right: 16px;
  width: 19px;
}
.league-name {
  margin-right: 9px;
}

.league-page {
  display: flex;
  flex-direction: row;
  padding: 16px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  justify-content: space-between;
}
.league {
  display: flex;
  width: 66%;
  flex-direction: column;
  justify-content: space-around;
}
.extra {
  background: url('https://improvers-prod-images-bucket.s3.eu-central-1.amazonaws.com/scorebord/Champions_League_Location.png');
  width: 33%;
  margin-left: 9px;
  background-size: cover;
}
.league-header {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  margin-bottom: 16px;
}
.league-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.league-partners {
  background: rgba(255, 255, 255, 0.1);
  width: 32.5%;
}

.league-title {
  font-family: Archivo Narrow;
  font-size: 60px;
  font-weight: 700;
  line-height: 81px;
  letter-spacing: 0em;
  text-align: left;
}
.league-logo-champ {
  background-image: url('/img/champion-big.png');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center;
  height: 100%;
  width: 10%;
}
.league-partner {
  box-sizing: border-box;
}
.league-inner {
  display: flex;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: rgba(255, 255, 255, 0.1);
  padding-top: 13px;
  padding-bottom: 13px;
  margin-left: 13px;
  margin-right: 13px;
  line-height: 17px;
}

.league-header-right {
  margin-left: auto;
  margin-top: 20px;
}



</style>


<script>

import { useStore } from 'vuex'
import { onMounted, ref, computed } from 'vue'
import { useFetch } from '@/composables/useFetch';
import Footer from '@/components/Footer.vue';
import shared from '../shared/shared.js';

export default {
  components: {
        Footer
  },
  setup() {
    const store = useStore();
    const { formatFee } = shared()

    const loadData = async () => {

      const league = await useFetch(process.env.VUE_APP_CHAMPIONS_LEAGUE, { addQueryParams: true }).then((response) => response.json())
      if (league.data.length > 0) {
        store.dispatch('setChampionsLeague', league.data[0]);
        console.log(store.state.champions_league)
        const filter0 = `&filter={"league":{"_eq":${league.data[0].id}}}`
        const filter1 = `&filter={"league_id":{"_eq":${league.data[0].id}}}`

        const league_data_url = `${process.env.VUE_APP_CHAMPIONS_LEAGUE_DATA}${filter0}`;
        const league_compliance_url = `${process.env.VUE_APP_CHAMPIONS_LEAGUE_COMPLIANCE}${filter1}`;
        const league_reviews_url = `${process.env.VUE_APP_CHAMPIONS_LEAGUE_REVIEWS}${filter1}`;
        const league_exclusions_url = `${process.env.VUE_APP_LEAGUE_EXCLUSIONS}`;

        const league_data = useFetch(league_data_url).then((response) => response.json())
        const league_compliance = useFetch(league_compliance_url).then((response) => response.json())
        const league_reviews = useFetch(league_reviews_url).then((response) => response.json())
        const league_exclusions = useFetch(league_exclusions_url).then((response) => response.json())

        Promise.all([league_data, league_compliance, league_reviews, league_exclusions]).then((fetched) => {
          store.dispatch('setChampionsLeagueData', fetched[0].data);
          store.dispatch('setChampionsLeagueCompliance', fetched[1].data);
          store.dispatch('setChampionsLeagueReviews', fetched[2].data);
          store.dispatch('setLeagueExclusions', fetched[3].data);
          loaded.value = true
        })
      }
    }

    const formatDate = (date) => {
      const dt = new Date(date)
      let options = {
        month: "short",
        day: "numeric",
      };

      return new Intl.DateTimeFormat("nl-NL", options).format(dt).toUpperCase();
    }

    const get_league_data = computed(function () {

      console.log(store.state.contacts)

      return store.state.champions_league_data.reduce((acc,cur) => {
        const compliance = store.state.champions_league_compliance.find((el)=> el.partner_id === cur.user_id) || null //returns null if undefined
        const reviews = store.state.champions_league_reviews.find((el)=> el.partner_id === cur.user_id) || null
        const compliance_score = compliance !== null ? compliance.compliance_pct : null
        const contact = store.state.contacts.find((el) => el.ext_sf_partner_id === cur.user_id) || null
        const review_score = reviews !== null ? reviews.score : null
        const is_compliant = compliance !== null ? compliance_score >= 90 : true
        const quality_ok = reviews !== null ? review_score >= 3.5 : true
        const exclusion = store.state.league_exclusions.some((el) => el.partner.user_id === cur.user_id)
        console.log('exclusion', exclusion)
        // console.log('exclusion', exclusion)
        // if (cur.user_id === "25088"){
        //   console.log("partner",cur.user_id, exclusion, store.state.league_exclusions)
        // }

        if (contact == null) {
          console.log('no contact',cur.user_id )
        } else if (contact.has_signed_partner_agreement != 'True') {
          console.log('no povk',cur.user_id, contact.first_name, contact.last_name )
        } else if (exclusion)
          console.log('partner excluded, see league_exclusions in flex',cur.user_id, contact.first_name, contact.last_name)
        else {
          acc.push({
            "user_id": cur.user_id,
            "amount": cur.amount,
            "first_name": cur.user.first_name,
            "last_name": cur.user.last_name,
            "company_name": contact.company_name,
            "compliance_score": compliance_score,
            "is_compliant": is_compliant,
            "quality_ok": quality_ok,
            "qualified": is_compliant && quality_ok
          })
        }
        return acc
      },[])
      .filter((el) => el.qualified === true)
      .sort((a, b) => (b.amount - a.amount))
      .slice(0, 60)
    })


    const get_league = computed(function () {
      return store.state.champions_league
    })

    const loaded = ref(false)


    onMounted(async function () {
     if(store.state.champions_league === null) {
        loadData()
     }
    })

    return {loaded, get_league, get_league_data, formatDate, formatFee}
  }
}
</script>