<template>
  <div class="last_sales_widget">
    <div class="col-header">
      <h1>Laatste Sales {{ header }}</h1>
    </div>
    <transition-group name="list-partner">

      <div v-for="(item, index) in recentSales" :key="item.sale_reference" class="list-partner-item"
        :class="[dynaclass(index)]">
        <template v-if="index <= 2">
          <!-- <div class="partner-foto" :class="chooseColor(item.first_name.length + item.last_name.length)"> -->

          <div v-if="item.certified_partner_id" class="partner-foto" :class="getLogo(item.certified_partner_id)">
          </div>
          <div v-else class="partner-foto" :class="getLogo(item.certified_partner_id)">
            <!-- {{ getInitials(item.first_name, item.last_name) }} -->
          </div>
          <h2 v-if="item.certified_partner_id" class="name">{{ item.first_name }} {{ item.last_name }}</h2>
          <h2 v-else class="name">{{ item.company_name }}</h2>
          <img class="logo" :src="item.logo_url" />
          <div class="advertiser">{{ item.name }}</div>
          <div class="time">{{ item.sale_closed_time.slice(0, -3) }}</div>
          <p class="product">{{ getProduct(item.project_code.substr(0, 4), item.product_description) }}</p>
          <div class="revenue-sale" :class="{ 'hide-cols': lessCols() }">&euro;{{ formatFee(item.fee_partner) }}</div>
        </template>
        <template v-else>
          <div class="row-sales" :class="[getRowComplianceClass(item.compliance), { 'hide-cols': lessCols() }]">
            <div class="time">{{ item.sale_closed_time.slice(0, -3) }}</div>
            <div v-if="item.certified_partner_id" class="name">{{ item.first_name }} {{ item.last_name }}</div>
            <div v-else class="name">{{ item.company_name }}</div>
            <div class="compliance" :class="getItemComplianceClass(item.compliance)">
              {{ item.compliance }}{{ item.compliance !== null ? '%' : '' }}</div>
            <div class="revenue-sale" :class="{ 'hide-cols': lessCols() }">&euro;{{ formatFee(item.fee_partner) }}</div>
            <img class="logo" :src="item.logo_url" />
            <div class="product">
              {{ getProduct(item.project_code.substr(0, 4), item.product_description) }}
            </div>
          </div>
        </template>
      </div>
    </transition-group>
  </div>
</template>

<script>
// import Mixin from '../shared/mixin';
import { useStore } from 'vuex'
import { defineComponent, computed } from 'vue'
import shared from '../shared/shared.js';
import { useShowMoneyValues } from '@/composables/useShowMoneyValues';
// import { exportNamespaceSpecifier } from '@babel/types';
// import { COMPARISON_BINARY_OPERATORS } from '@babel/types';

export default defineComponent({
  name: 'PartnerDefault',
  props: {
    header: {
      type: String,
      required: false,
      default: 'Improvers'
    },
    certifiedPartnerId: {
      type: String,
      required: false,
    },
  },
  setup(props) {

    const { chooseColor, getItemComplianceClass, getRowComplianceClass, getInitials, formatFee, getProduct, getLogo } = shared()
    const store = useStore();

    function lessCols() {
      // console.log('less', useShowMoneyValues.value)
      return useShowMoneyValues.value ? false : true;
    }

    function dynaclass(index) {
      if (index === 0) {
        return 'row-highlight';
      }
      if (index === 1) {
        return 'row-highlight middle';
      }
      if (index === 2) {
        return 'row-highlight small';
      }
      return 'row-sale';
    }

    const recentSales = computed(function () {
      return store.getters.getDetails15New(props.certifiedPartnerId);
    });

    return { dynaclass, chooseColor, getItemComplianceClass, getRowComplianceClass, getInitials, formatFee, getProduct, recentSales, getLogo, useShowMoneyValues, lessCols }
  }

})
</script>
